import React from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './login.module.scss';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import AccessCodeInput from '../../components/AccessCodeInput';
import logo from '../../images/logo.svg';
import useAuth from '../../hooks/useAuth';

function LoginPage() {
  const [code, setCode] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const { token, onLogin } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();
  const origin = location.state?.redirectTo || '/';

  if (token) {
    return <Navigate to="/" replace />;
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!code) return;

    setLoading(true);
    setErrorMsg('');

    try {
      await onLogin(code);
      navigate(origin);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMsg(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>UST - Login</title>
      </Helmet>

      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.canario} />
          <div className={styles.floatingImage} />
          <form className={styles.loginForm} onSubmit={handleSubmit}>
            <img className={styles.logo} src={logo} alt="logo" />
            <AccessCodeInput
              value={code}
              onChange={(value: string) => setCode(value)}
            />
            <Button disabled={loading} type="submit">
              {loading ? 'Entrando...' : 'Entrar'}
            </Button>

            {errorMsg && <p className={styles.error}>{errorMsg}</p>}
          </form>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default LoginPage;
