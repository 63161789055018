import React from 'react';
import classnames from 'classnames';
import styles from './Button.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary';
}

function Button({ className, ...props }: Props) {
  const { variant = 'primary' } = props;

  return (
    <button
      type="button"
      className={classnames(styles.button, styles[variant], className)}
      {...props}
    />
  );
}

export default Button;
