import React from 'react';
import { BASE_API_URL } from '../utils/constants';
import useAuth from '../hooks/useAuth';

export interface AppContextType {
  watchedVideos: {
    [key: string]: boolean;
  };
  isWatched: (videoId: string) => boolean;
  markAsWatched: (videoId: string) => void;
  isLoading: boolean;
}

const AppContext = React.createContext<AppContextType | null>(null);

interface AppProviderProps {
  children: React.ReactNode;
}

function AppProvider({ children }: AppProviderProps) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [watchedVideos, setWatchedVideos] = React.useState<
    AppContextType['watchedVideos']
  >({});

  const { token } = useAuth();

  const fetchWatchedVideos = React.useCallback(async () => {
    if (!token) return;

    try {
      const response = await fetch(
        `${BASE_API_URL}/products-codes/view-history`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      const data = await response.json();
      // eslint-disable-next-line no-underscore-dangle
      setWatchedVideos(data._viewHistory || {});
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  React.useEffect(() => {
    fetchWatchedVideos();
  }, [fetchWatchedVideos]);

  const updateWatchedVideos = React.useCallback(
    async (viewHistory: AppContextType['watchedVideos']) => {
      try {
        fetch(`${BASE_API_URL}/products-codes`, {
          method: 'PUT',
          body: JSON.stringify({ viewHistory }),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [token],
  );

  const markAsWatched = React.useCallback(
    (videoId: string) => {
      const newWatchedVideos = { ...watchedVideos, [videoId]: true };
      setWatchedVideos(newWatchedVideos);
      updateWatchedVideos(newWatchedVideos);
    },
    [watchedVideos, updateWatchedVideos],
  );

  const value = React.useMemo(
    () => ({
      watchedVideos,
      markAsWatched,
      isWatched: (videoId: string) => watchedVideos[videoId] || false,
      isLoading,
    }),
    [markAsWatched, watchedVideos, isLoading],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export { AppProvider, AppContext };
