import React from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import { BASE_API_URL } from '../utils/constants';

export interface AuthContextType {
  token: string | null;
  onLogin: (code: string) => void;
  onLogout: () => void;
}

const AuthContext = React.createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

function AuthProvider({ children }: AuthProviderProps) {
  const [token, setToken] = useLocalStorage('token', null);

  const verifyToken = React.useCallback(async () => {
    const response = await fetch(
      `${BASE_API_URL}/products-codes/verify-token`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (response.status === 200) return;

    setToken(null);
  }, [setToken, token]);

  React.useEffect(() => {
    verifyToken();
  }, [verifyToken]);

  const handleLogin = React.useCallback(
    async (code: string) => {
      const response = await fetch(`${BASE_API_URL}/products-codes/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code: code.toUpperCase() }),
      });

      if ([200, 201].includes(response.status)) {
        const newToken = await response.text();
        setToken(newToken);
        return;
      }

      if (response.status === 400) {
        throw new Error('Código inválido');
      }

      throw new Error('Erro ao realizar login');
    },
    [setToken],
  );

  const value = React.useMemo(
    () => ({
      token,
      onLogin: handleLogin,
      onLogout: () => setToken(null),
    }),
    [token, handleLogin, setToken],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { AuthProvider, AuthContext };
