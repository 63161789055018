import React from 'react';
import logoutIcon from '../../images/logout-icon.svg';
import logo from '../../images/logo-main.svg';
import styles from './Header.module.scss';
import ConfirmLogoutModal from '../ConfirmLogoutModal';

function Header() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <header className={styles.header}>
      <ConfirmLogoutModal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      />

      <img className={styles.logo} src={logo} alt="logo" />

      <button type="button" onClick={() => setIsModalOpen(true)}>
        <img className={styles.logout} src={logoutIcon} alt="logout" />
      </button>
    </header>
  );
}

export default Header;
