import React from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import styles from './ConfirmLogoutModal.module.scss';
import useAuth from '../../hooks/useAuth';
import Button from '../Button';

interface Props {
  onCancel: () => void;
  open: boolean;
}

function ConfirmLogoutModal({ onCancel, open }: Props) {
  const { onLogout } = useAuth();

  const render = () => (
    <div className={classnames(styles.container, { [styles.open]: open })}>
      <div className={styles.modal}>
        <h1>Tem certeza que deseja sair?</h1>
        <p>
          Se você sair, precisará digitar o código de acesso novamente para
          acessar sua conta.
        </p>

        <div className={styles.actions}>
          <Button variant="secondary" onClick={onCancel}>
            Cancelar
          </Button>
          <Button onClick={onLogout}>sair</Button>
        </div>
      </div>

      <div className={styles.overlay} />
    </div>
  );

  return createPortal(render(), document.getElementById('root') as HTMLElement);
}

export default ConfirmLogoutModal;
