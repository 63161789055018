import { IMaskInput } from 'react-imask';
import { useId, useState } from 'react';
import classnames from 'classnames';
import styles from './AccessCodeInput.module.scss';
import hintIcon from '../../images/hint-icon.svg';

interface Props {
  onChange(value: string): void;
  value: string;
}

function AccessCodeInput(props: Props) {
  const id = useId();
  const [showHint, setShowHint] = useState(false);

  const toggleHint = () => setShowHint(!showHint);

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.hint, {
          [styles.visible]: showHint,
        })}
      >
        {`Este É O Código Que É Adquirido Através Do Nosso Almanaque. Se Você
          Não O Adquiriu Ainda, `}
        <a href="oi">Clique Aqui</a>
      </div>

      <label htmlFor={id}>
        Código de Acesso
        <button type="button" onClick={toggleHint}>
          <img src={hintIcon} alt="hint-icon" />
        </button>
      </label>

      <IMaskInput
        mask="***-***"
        autoComplete="off"
        unmask
        value={props.value}
        placeholder="XXX-XXX"
        id={id}
        maxLength={7}
        className={styles.input}
        onAccept={props.onChange}
      />
    </div>
  );
}

export default AccessCodeInput;
