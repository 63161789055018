import React from 'react';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import styles from './VideoContent.module.scss';
import Button from '../Button';
import Video from '../Video';
import episodes, { type Episode } from '../../data/episodes';
import useIsMobile from '../../hooks/useIsMobile';

interface Props {
  episode: Episode;
}

function VideoContent({
  episode: { title, subtitle, videoUrl, url, id },
}: Props) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const episodeIndex = episodes.findIndex((ep) => ep.url === url);
  const previousEpisode = episodes[episodeIndex - 1];
  const nextEpisode = episodes[episodeIndex + 1];

  const renderVideo = () => (
    <Video id={id} videoUrl={videoUrl} title={`${title} - ${subtitle}`} />
  );

  if (isMobile) return renderVideo();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>{title}</h1>
        <p>{subtitle}</p>
        <div className={styles.video}>{renderVideo()}</div>
        <div
          className={classnames(styles.actions, {
            [styles.prevOnly]: previousEpisode && !nextEpisode,
            [styles.nextOnly]: nextEpisode && !previousEpisode,
          })}
        >
          {previousEpisode && (
            <Button
              onClick={() => navigate(previousEpisode.url)}
              variant="secondary"
            >
              Anterior
            </Button>
          )}
          {nextEpisode && (
            <Button
              onClick={() => navigate(nextEpisode.url)}
              variant="secondary"
            >
              Próximo
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default VideoContent;
