import React from 'react';
import { Helmet } from 'react-helmet';
import VideoContent from '../../components/VideoContent';
import type { Episode } from '../../data/episodes';

interface Props {
  episode: Episode;
}

function EpisodesPage({ episode }: Props) {
  return (
    <>
      <Helmet>
        <title>{`UST - ${episode.title}`}</title>
      </Helmet>
      <VideoContent episode={episode} />
    </>
  );
}

export default EpisodesPage;
