import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import styles from './Layout.module.scss';
import logo from '../../images/logo-main.svg';
import MenuItem from '../MenuItem';
import Header from '../Header';
import episodes from '../../data/episodes';
import useAuth from '../../hooks/useAuth';
import useApp from '../../hooks/useApp';
import useIsMobile from '../../hooks/useIsMobile';

function Layout() {
  const { token } = useAuth();
  const location = useLocation();
  const isMobile = useIsMobile();
  const { watchedVideos, isLoading } = useApp();

  const EpisodeList = episodes.map((episode) => (
    <MenuItem key={episode.id} episode={episode} />
  ));

  const nextEpisodeUrl = React.useMemo(() => {
    let nextEpisode = episodes.find((episode) => !watchedVideos[episode.id]);
    if (!nextEpisode) [nextEpisode] = episodes;

    return nextEpisode.url || '/';
  }, [watchedVideos]);

  if (!token) {
    return (
      <Navigate state={{ redirectTo: location.pathname }} replace to="/login" />
    );
  }

  if (!isLoading && location.pathname === '/') {
    return (
      <Navigate
        state={{ redirectTo: location.pathname }}
        replace
        to={nextEpisodeUrl}
      />
    );
  }

  if (isMobile) {
    return (
      <div className={styles.mobileContainer}>
        <Header />
        <main>
          <Outlet />
        </main>
        <div className={styles.menu}>
          <ul>{EpisodeList}</ul>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.menu}>
        <img className={styles.logo} src={logo} alt="logo" />
        <div className={styles.menuHeader}>EPISÓDIOS</div>
        <ul className={styles.menuContent}>{EpisodeList}</ul>
      </div>
      <main>
        <Outlet />
      </main>
    </div>
  );
}

export default Layout;
