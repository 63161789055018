import React from 'react';
import Player from '@vimeo/player';
import styles from './Video.module.scss';
import useApp from '../../hooks/useApp';

interface Props {
  videoUrl: string;
  title: string;
  id: string;
}

function Video({ videoUrl, title, id }: Props) {
  const playerRef = React.useRef<HTMLIFrameElement>(null);
  const { markAsWatched } = useApp();

  React.useEffect(() => {
    const iframe = playerRef.current;
    if (!iframe) return;

    const player = new Player(iframe);

    player.on('ended', () => {
      markAsWatched(id);
    });
  }, [id, markAsWatched]);

  return (
    <iframe
      className={styles.video}
      ref={playerRef}
      src={videoUrl}
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
      title={title}
    />
  );
}

export default Video;
