import React from 'react';
import classnames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './MenuItem.module.scss';
import useIsMobile from '../../hooks/useIsMobile';
import useApp from '../../hooks/useApp';
import type { Episode } from '../../data/episodes';

interface Props {
  episode: Episode;
}

const actionKeys = ['Enter', ' '];

function MenuItem({ episode }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const { isWatched } = useApp();
  const { title, subtitle, id, url } = episode;

  const isSelected = location.pathname === url;

  const handleNavigation = () => navigate(url);
  const itemRef = React.useRef<HTMLLIElement>(null);

  React.useEffect(() => {
    if (!isSelected || !itemRef.current) return;

    itemRef.current.scrollIntoView({
      behavior: 'auto',
      block: 'center',
    });
  }, [isSelected]);

  if (isMobile) {
    return (
      <li
        ref={itemRef}
        className={classnames(styles.itemMobile, {
          [styles.selected]: isSelected,
        })}
      >
        <button onClick={handleNavigation} type="button">
          <div
            className={classnames(styles.thumb, {
              [styles.watched]: isWatched(id),
            })}
          >
            <img src={episode.thumbnail} alt={title} />
          </div>
          <div className={styles.info}>
            <p className={styles.title}>{title}</p>
            <p className={styles.subtitle}>{subtitle}</p>
          </div>
        </button>
      </li>
    );
  }

  return (
    <li
      ref={itemRef}
      className={classnames(styles.item, { [styles.selected]: isSelected })}
    >
      <button
        onClick={handleNavigation}
        type="button"
        onKeyUp={(event) => {
          if (actionKeys.includes(event.key)) handleNavigation();
        }}
      >
        {title}
      </button>
    </li>
  );
}

export default MenuItem;
